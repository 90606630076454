<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> Sun - Fri : 10:00AM - 06:00PM</li>
                        <li><i class="icofont-location-pin"></i> {{ this.company?.town }} - {{ this.company?.city }}, {{ this.company?.country }}</li>
                        <li><i class="icofont-phone"></i> <a href="tel:{{ this.company?.phone }}">{{ this.company?.phone }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li><a href="{{ this.company?.facebook }}" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="{{ this.company?.twitter }}" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="{{ this.company?.linkedin }}" target="_blank"><i class="icofont-linkedin"></i></a></li>             
                        <li><a href="{{ this.company?.instagram }}" target="_blank"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>              
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item"><a href="/#" class="nav-link">Home</a></li>

                        <li class="nav-item"><a href="/#about-us" class="nav-link">About Us</a></li>

                        <li class="nav-item"><a href="/#productions" class="nav-link">Productions</a></li>

                        <li class="nav-item"><a href="/#technical" class="nav-link">Technical Assistance</a></li>

                        <li class="nav-item"><a href="/#contact" class="nav-link">Contact</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>