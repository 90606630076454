import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Company } from '../../../interfaces/company';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public company: Company = null;

  constructor(private dataService: DataService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    await this.dataService.getCompany().then((data: Company) => {
      this.company = data;
    });
  }

}
