import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../interfaces/company';

export const TABLE_COMPANY = 'company';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }

  async getCompany(): Promise<any> {
    const company = await this.supabase
      .from(TABLE_COMPANY)
      .select('*')
      .eq('title', 'prokarbon').single();
    return company.data;
  }

}
