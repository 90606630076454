import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Company } from '../../../interfaces/company';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public company: Company = null;
  constructor(private dataService: DataService) { }

  async ngOnInit(): Promise<void> {
    await this.dataService.getCompany().then((data: Company) => {
      this.company = data;
    });
  }

}
