<section id="home">
<div class="home-section">
    <div class="home-slider-area owl-carousel owl-theme">
        <div class="home-slider-item items-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

<section id="icons" class="service-section pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="service-card">
                    <div class="blepharoplasty"></div>
                    <h3>Customer<br>Oriented</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="service-card">
                    <div class="ptosis"></div>
                    <h3>Stable<br>Supply</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="service-card">
                    <div class="chalazion"></div>
                    <h3>Strong Post-sales<br>Support</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="service-card">
                    <div class="eyeprothesis"></div>
                    <h3>Visionary<br>Market Insides</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="about-us" class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2><span>Who are we?</span></h2>
            <h3>We Fuel Your Metallurgy Business!</h3>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/whoarewe.png" alt="whoarewe">
                    </div>

                    <div class="team-text">
                        <h4> </h4>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-7 col-md-6 col-sm-6">
                <div class="about-text">
                    <div class="about-text">
                        <p>ProKarbon established in Turkey in Jun 2023. 
                            We dedicated our business in providing qualified consumables, such as electrodes, bauxite, refractory materials and etc., for melt-shops. 
                            The majority of materials that we supply are made in China, from well esteemed manufactures who also play important role in global market, with their stable quality and reasonable prices. Our company has strong cooperation with these dominant Chinese manufactures which guarantees the materials we provide.
                        </p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>

<section id="productions" class="service-section pb-70">
    <div class="service-style-two">
        <div class="container">
            <div class="section-head text-center">
                <h2><span>The Production</span></h2>
            </div>
            <div class="service-slider-wrapper owl-carousel owl-theme">
                <div class="row align-items-center bg-white">
                    <div class="col-lg-3">
                        <div class="service-img">
                            <img src="assets/img/front/5.png" alt="team member">
                        </div>
                    </div>

                    <div class="col-lg-9">
                        <div class="service-text-two">
                            <div class="about-text">
                                <p>ProKarbon has a partnership with an ISO9001:2008 certified Chinese Graphite
                                    Electrodes producer. The plant facilities use modern technology and
                                    equipment, with lengthwise graphitization furnaces. ProKarbon Graphite
                                    Electrodes have been continuously improved, and are produced with top
                                    quality needle coke and pitch. All products are subjected to rigorous testing
                                    and control procedures, in order to guarantee a high and consistent quality
                                    to the end users.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center bg-white">
                    <div class="col-lg-3">
                        <div class="service-img">
                            <img src="assets/img/front/6.png" alt="team member">
                        </div>
                    </div>

                    <div class="col-lg-9">
                        <div class="service-text-two">
                            <div class="about-text">
                                <p>ProKarbon technical staff follows step by step the manufacturing of our graphite
                                    electrodes.</p>
                                <p>The Group has a production line dedicated to its materials, on which it has
                                    complete quality control: from raw mate-rials choice, to the baking and the
                                    graphitization process, ending with the machining of the final products.
                                    A global sales and service network, with offices located in every continent,
                                    enables Trasteel to provide a high level service in the industry, both for timely
                                    supplies and technical support. The main goals are to increase melt shops’
                                    performances and to reduce transformation costs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
        </div>
    </div>
</section>

<section id="faq" class="why-choose-section why-choose-bg" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 why-choose-img"></div>

            <div class="col-lg-6 offset-lg-6 offset-md-0">
                <div class="why-choose-text">
                    <div class="section-head">
                        <h2>Frequently Asked Questions</h2>
                        <p>Graphite Electrodes Applications</p>
                    </div>
                </div>
                <div class="why-choose-accordian">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <a href="#" class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       1 - Why Are Graphite Electrodes Used In Electrolysis?
                                    </a>
                                </h2>
                            </div>                           
                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    The main reason that graphite electrodes are used in electrolysis is that graphite is an excellent conductor. The structure of graphite is such that it has a large number of electrons floating freely between the different layers of atoms (graphite bonds are formed of only three out of the four electron shells of the carbon atom, leaving the fourth electron to move freely). These electrons act as a powerful conductor, enabling the electrolysis process to progress smoothly. In addition, graphite is economical, stable at high temperatures and hard-wearing. For all these reasons, graphite electrodes are frequently used in electrolysis.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        2 - Why Is Graphite Used As An Electrode In Electrolysis?
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    The atomic structure of graphite results in a large number of electrons not being bonded, allowing them to migrate between the layers of graphite. It is this large number of free electrons (electron delocalization) that give graphite its excellent conductive properties. As well as being a good conductor, graphite is also cheap, robust and easily accessible – all further reasons why it’s commonly used as an electrode.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        3 - Are Graphite Electrodes Suitable for Electrolysis??
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Yes! Graphite’s excellent conductive properties, coupled with its high melting point (enabling it to be used appropriately in a wide range of different electrolysis reactions), low price and toughness means it’s a good choice for an electrolysis electrode.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        4 - What Happens to a Solution During Electrolysis When Graphite Electrodes Are Used?
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    Graphite enables positively charged ions (metals and hydrogen) to obtain electrons from the negatively charged electrode. Conversely, negatively charged ions lose electrons (oxidation).
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <h2 class="mb-0">
                                    <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        5 - How can ProKarbon help with the correct material selection for our application?
                                    </a>
                                </h2>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div class="card-body">
                                    ProKarbon has team members with experience in selecting raw material and manufacturing the graphite we currently machine from our vendors. We understand the complete make-up of graphite, what the unique characteristics of each grade are, and how the characteristics will support and enhance performance in each different application.
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

        <!-- <div class="why-choose-shape">
            <img src="assets/img/front/faq.png" alt="shape">
        </div> -->
    </div>
</section>

<section id="technical" class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2><span>TECHNICAL ASSISTANCE</span></h2>
            <h3>Technical Assistance and Optimization of Refractories Applications</h3>
        </div>

        <div class="row">
            <div class="col-lg-1 col-md-6 col-sm-6">
            </div> 
            
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="about-text">
                    <div class="about-text">
                        <p>ProKarbon prouds itself on the quality of its engineering staff, which is capable
                            of optimizing the performance of its materials even in extreme working
                            conditions.</p>
                        <p>Besides, it can offer a wide range of services, teaming up with customer’s
                            technical staff and reducing the trans-formation costs of the mill.
                            Trasteel technical team provides complete assistance and consulting in
                            order to maximize the results of its products. Its services go beyond a simple
                            product-related service, as listed below:</p>
                        <p>
                                <li>Study, design and optimization of all refractories lining by using thermal calculation and dynamic model</li>
                                <li>On-site training to steel plant technicians on the refractory operation</li>
                                <li>Development of 4.0 Industry, by providing software and tools in order to monitor the refractories life with forecast analysis and therefore bettering the stock management</li>
                                <li>Detailed practical and theoretical studies related to the electric arc furnaces and their operations</li>
                                <li>Monitoring and optimization of operational, mechanical and electrical variables which affect the performances of the electric arc furnaces</li>
                                <li>Monitoring and optimization of operational and mechanical performance of the refractory</li>
                                <li>Calculation of productivity increase</li>
                                <li>Furnace start-up assistance</li>
                                <li>Furnace Regulation</li>
                                <li>Hydraulics</li>
                                <li>Evaluation of power supply system</li>
                                <li>Evaluation of transformer performance</li>
                                <li>Evaluation of load current and current balance between phases</li>
                                <li>Optimization of melting efficiency</li>
                                <li>Optimization of graphite electrodes consumption</li>
                                <li>Customized Reporting</li>
                                <li>Interaction with customer’s data – PLC</li>
                                <li>Inspection of accessories</li>
                        </p>    
                    </div>
                </div>
            </div>
            
            <div class="col-lg-1 col-md-6 col-sm-6">
            </div> 
        </div>
    </div>
</section>