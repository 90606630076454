<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/front/contact-img.png" alt="contact image">      
                    </div>           
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>We Love to <span> Hear from You</span></h2>
                            <p>Feel free and share with us. We will get you</p>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                                        </div>
                                    </div>
                                
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn page-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area">
    <section id="contact">
    <div class="container">
        <div class="row">

            <div class="col-lg-9 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="find-us">
                        <li>                                   
                            <i class="icofont-location-pin"></i> {{ this.company?.address }} {{ this.company?.address2 }}
                            {{ this.company?.town }} - {{ this.company?.city }}, {{ this.company?.country }}        
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:{{ this.company?.phone }}"> {{ this.company?.phone }}  </a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="mailto:{{ this.company?.email }}"> {{ this.company?.email }} </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget"> <h3>{{ this.company?.company_name }}</h3> 
                    <div class="email">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                            <button class="default-btn electronics-btn" type="submit"><i class="icofont-location-arrow"></i></button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a href="{{ this.company?.facebook }}" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="{{ this.company?.twitter }}" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="{{ this.company?.linkedin }}" target="_blank"><i class="icofont-linkedin"></i></a></li>             
                            <li><a href="{{ this.company?.instagram }}" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-end">
                        <p>Copyright © 2023 - {{ this.company?.company_name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>